.mtska-chart {

    &.mtska-chart-scorecard,
    &.mtska-chart-kpi,
    &.mtska-chart-user {
        height: 220px;
        border-radius: $borderradius;
        box-shadow: $boxshadow;
        border: $boxborder;
        background-color: $white;
        padding: 24px;
        display: inline-flex;
        flex-direction: row;

        .scorewrapper {
            position: relative;

            .title {
                color: var(--app-surface-dark);
                @include maxlines(1);
            }

            .score {
                font-size: 36px;
                font-weight: 600;
                letter-spacing: -2px;
                color: var(--app-surface-shadowed);
            }

            .delta {
                position: absolute;
                bottom: -5px;
                border: 0.5px solid $neutral-500;
                background-color: $neutral-050;
                font-size: 12px;
                font-weight: 400;

                &.negative {
                    border-color: $danger-500;
                    background-color: $danger-050;
                    color: $danger-500;
                }

                &.positive {
                    border-color: $success-500;
                    background-color: $success-050;
                    color: $success-500;
                }
            }
        }

        .chartwrapper {
            width: 70%;
        }
    }

    &.mtska-chart-kpi,
    &.mtska-chart-user {
        position: relative;
        height: auto;
        gap: 10px;
        aspect-ratio: 2/1.2;
        max-height: 130px;

        .iconwrapper {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: $borderradius 0 0 $borderradius;
            width: 30%;
            max-width: 100px;
            height: 100%;
            background-color: var(--app-surface-light);
            border: 1px solid $white;
            border-right-width: 0.5px;
            border-right-color: $primary-200;

            svg {
                font-size: 24px;
                color: var(--app-icon-primary);
                position: absolute;
                top:50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                display: block;
            }

            .input-button-filter-wrapper {
                left:-1px;
                bottom: -1px;
                position: absolute;
                text-align: center;
                width: calc(100% + 1px);

                .input-button-filter {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 28px;
                    height: 28px;
                    min-height: 28px;
                    padding-top: 0;
                    padding-bottom: 0;
                    width: 100%;
                    border-radius: 0 0 0 5px;
                    background-color: $neutral-300;
                    border-left: 1px solid $neutral-200;
                    border-bottom: 1px solid $neutral-200;
                    &:hover {
                        background-color: var(--app-main);
                    }
                }
            }
        }

        .scorewrapper {
            margin-left: min(calc(30% + 10px), 110px);
            .score {
                font-size: 40px;
            }
        }

        .userwrapper {
            .name {
                font-size: 26px;
                line-height: 40px;
                margin-top: 20px;
            }

            .email {
                color: var(--app-surface-dimmed);
            }
        }

        &.small {
            .scorewrapper {
                .score {
                    font-size: 30px;
                    letter-spacing: normal;
                }
            }
        }

        &.superuserData {
            .iconwrapper {
                background-color: $primary-500;
                svg {
                    color: $white;
                }
            }
        }
    }

    &.mtska-chart-vehicle-kilometer {
        width: auto;
        height: 265px;
        border-radius: $borderradius;
        box-shadow: $boxshadow;
        border: $boxborder;
        background-color: $white;
        padding: 24px;
        display: inline-flex;
        flex-direction: row;
        font-family: $font-primary;
        gap: 30px;

        .chartwrapper {
            width: 640px;
            height: 265px;
        }

        .forecastwrapper {
            margin-top: 30px;

            .result {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .info {
                    display: flex;
                    gap: 10px;
                    font-size: 14px;

                    .key {
                        font-size: 12px;
                        font-weight: 300;
                        min-width: 150px;
                    }

                    .value {
                        font-weight: 500;
                        min-width: 130px;
                    }

                    &.forecast {
                        color: $secondary-500;
                    }

                    &.warning {
                        color: $secondary-500;
                        background-color: $secondary-100;
                        border-radius: 5px;
                        margin: -5px -5px;

                        .key {
                            padding: 5px 0 5px 5px
                        }

                        .value {
                            padding: 5px 0;
                        }
                    }
                }
            }
        }
    }

    &.mtska-chart-table {
        .mtska-chart-table-data {
            margin:0 -20px;
            background-color: $primary-050;
        }
    }

   
}

.mtska-chart-wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;

    .mtska-chart {
        flex-grow: 1;
        flex-basis: 0;

        &.list-system {
            max-width: 32.3%;
        }
    }
}

@media screen and (max-width: 600px) {
    .mtska-chart-wrapper {
        flex-wrap: wrap;
        overflow: hidden;

        .mtska-chart.mtska-chart-kpi {
            height: 85px;
            padding: 10px 10px 10px 60px;

            .iconwrapper {
                width: 50px;

                svg {
                    font-size: 20px;
                    margin: 15px auto;
                }
            }

            .scorewrapper {
                width: 100%;
                overflow: hidden;

                .title {
                    font-size: 13px;
                    @include maxlines(1);
                }

                .score {
                    font-size: 30px;
                    top: 26px;
                    line-height: 30px;
                }
            }
        }
    }
}