.buttongroup.MuiButtonGroup-root {
    box-shadow: none;
    border-radius: 6px;

  
    >button {

        margin-right: 0 !important;

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 10px;
            font-weight: 300;
            font-size: 14px;
            border-right: 1px solid var(--app-main-shadowed);
        }

        &:last-child {
            margin-left: 0 !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-left: 5px;
            padding-right: 5px;
            border-left: 1px solid var(--app-main-subdued);
        }
    }

    .MuiButtonBase-root {
        text-transform: none;
    }
}

.buttongroup_action_positioner {
    z-index: 3;

    .buttongroup_action_wrapper {
        .buttongroup_action {
            // display: grid;
            // grid-template-columns: 30px auto;
            font-size: 14px;
            padding-left: 5px;
            padding-right: 15px;

            svg {
                width: 30px;
            }

            &[aria-disabled="true"] {
                display: none;
            }
        }
    }
}


@media screen and (max-width: 600px) {

    .buttongroup {
        &.MuiButtonGroup-root {
            >button {
                &:first-child {
                    white-space: nowrap;
                    font-size: 12px;
                    font-weight: 300;
                    letter-spacing: normal;
                    @include maxlines(1);

                    .MuiButton-startDecorator {
                        display: none;
                    }
                }
            }
        }
    }
}