.person-avatar-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .person-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 0.5px solid $neutral-300;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        font-size: 16px;
        color: $white;
        font-weight: 300;
        background-color: $neutral-050;
        box-shadow: $boxshadow;
        cursor: pointer;
        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }

    }

    .person-name {
        line-height: 40px;

        &.hasdetail {
            display: flex;
            flex-direction: column;
            line-height: 15px !important;
            .detail {
                font-size: 10px;
                color: $neutral-400;
            }
        }
    }
}