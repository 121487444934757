$fieldheight: 44px;

.form-element {
    font-family: $font-primary;

    .MuiInput-startDecorator,
    .MuiSelect-startDecorator,
    .MuiTextarea-startDecorator {
        width: 25px;
        height: $fieldheight;
        padding-right: 10px;
        border-right: 0.5px solid $neutral-200;

        svg {
            width: 13px;
        }

        &:empty {
            display: none;
        }
    }

    .MuiTextarea-startDecorator {
        height: auto;
        margin-bottom: 0;
        padding-top: 14px;
        padding-left: 7px;
        padding-right: 10px;
        width: 32px;
    }

    .MuiTextarea-endDecorator {
        margin: 0;
        width: 10px;
        text-align: center;
        padding: 14px 18px;
    }

    .form-element-control {
        .form-element-label {
            font-family: $font-primary;
            font-weight: 300;
            color: $neutral-700;
            font-size: 13px;
            user-select: text;
        }

        .form-element-field {
            border: 0.5px solid $neutral-200;
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
            border-radius: $borderradius;
            box-shadow: $boxshadow;
            min-height: $fieldheight;

            input,
            button {
                color: $neutral-700;
                border-radius: 8px;
                height: $fieldheight;
                font-size: 16px;
                font-weight: 400;

                &::placeholder {
                    font-weight: 300;
                    color: $neutral-300;
                    opacity: 1;
                }

                &[type="file"] {
                    padding: 10px;
                    height: 24px;
                    font-size: 14px;
                }
            }
        }

        .form-element-hint {
            font-size: 14px;
            font-weight: 400;
            color: $neutral-400;

            &:empty {
                margin: 0;
            }
        }

        .form-element-dropbox-area {

            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            color: #bdbdbd;
            outline: none;
            transition: border .24s ease-in-out;

            border-radius: $borderradius;
            box-shadow: $boxshadow;
            min-height: $fieldheight * 2;
            border: 1px dashed $neutral-200;
            cursor: pointer;
            
            max-height: 400px;
            overflow-y: auto;

            .filesPreview {
                display: grid;
                gap: 5px;
                list-style-type: none;
                margin: 0;
                padding: 0;
                width: 100%;

                .filePreview {
                    border: 0.5px solid $neutral-200;
                    border-radius: 5px;
                    display: flex;
                    gap: 10px;
                    padding: 10px;
                }
            }

            .info {
                line-height: 117px;
            }

            ul {
                &:empty {
                    display: none;
                }
            }

            &:hover {
                border-color: $secondary-500;
            }
        }

        &.error,
        &.form-element-error {

            .MuiInput-startDecorator,
            .MuiSelect-startDecorator,
            .MuiTextarea-startDecorator {
                border-right-color: $danger-400;
            }

            .form-element-label {
                color: $danger-400;
            }

            .form-element-field {
                border-color: $danger-400;
                background-color: $danger-050;

                svg {
                    path {
                        fill: $danger-400;
                    }
                }

                input,
                textarea {
                    color: $danger-400;
                }
            }

            .form-element-hint {
                color: $danger-400;
            }
        }

        &.required {
            .form-element-label {
                &::after {
                    content: "*";
                    padding-left: 5px;
                    color: $danger-500;
                }
            }
        }

        &.disabled,
        &.readonly {

            .MuiInput-startDecorator,
            .MuiSelect-startDecorator,
            .MuiTextarea-startDecorator {
                svg {
                    path {
                        fill: $neutral-400;
                    }
                }
            }

            .form-element-label {
                color: $neutral-400;
            }

            .form-element-field {
                input {
                    color: $neutral-500;
                }
            }

            .form-element-field {
                background-color: $neutral-100;
            }

            .form-element-hint {
                color: $neutral-300;
            }

            .form-element-relationship-options {
                ul {
                    >li {
                        color: $neutral-500;
                        background-color: $neutral-100;
                    }
                }
            }

            .form-element-field-select {
                button {
                    color: $neutral-500;
                }
            }

            &.error,
            &.form-element-error {
                .form-element-label {
                    color: $danger-300;
                }

                .form-element-field {
                    background-color: $danger-050;
                }

                .form-element-hint {
                    color: $danger-300;
                }

                .form-element-field {
                    input {
                        color: $danger-300;
                    }
                }
            }
        }
    }

    &.hidden {
        display: none;
    }

    &.empty {
        input[type="date"] {
            font-weight: 300;
            color: $neutral-300 !important;
        }

        .form-element-control {
            .form-element-field {
                button {
                    font-weight: 300;
                    color: $neutral-300;
                }
            }
        }
    }

    &.radio,
    &.checkbox {
        .form-element-field {
            padding: 12px;

            input {
                height: 24px;
                aspect-ratio: 1 / 1;
            }
        }
    }

    &.radio {
        .MuiRadio-root {
            margin: 0;
            padding: 8px 0;
        }
    }

    &.checkbox {
        .form-element-control {
            .form-element-label {
                margin: 0 0 0.375rem 0;
            }

            .form-element-field {
                display: flex;
                flex-direction: column;
                gap: 12px;

                ul,
                li {
                    padding: 0;
                }
            }
        }
    }

    &.textarea {
        .form-element-field {
            display: flex;
            flex-direction: row;
            gap: 3px;

            textarea {
                margin: 0;
                padding: 10px 0;

                &::placeholder {
                    font-weight: 300;
                    color: $neutral-300;
                    opacity: 1;
                }
            }
        }
    }

    &.switch {
        .form-element-control {
            flex-direction: column;

            .form-element-label {
                margin: 0 0 0.375rem 0;
            }

            .form-element-field {
                width: 100%;

                .MuiSwitch-startDecorator {
                    padding: 0 10px;

                    &:empty {
                        padding: 0 4px;
                    }
                }

                .MuiSwitch-endDecorator {
                    svg {
                        position: absolute;
                        right: 12px;
                        top: 14px;
                    }
                }
            }
        }
    }

    &.relationship {
        &.relationship-single {
            .form-element-relationship-options {
                ul {
                    >li {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .form-element-relationship-options {
            ul {
                padding: 0;

                >li {
                    border-radius: 8px;
                    padding: 0 10px;
                    border: 0.5px solid $neutral-200;
                    border-radius: $borderradius;
                    box-shadow: $boxshadow;
                    min-height: $fieldheight;
                    background-color: var(--app-surface-lightest);
                    margin-bottom: 10px;
                    overflow: hidden;

                    button {
                        background-color: var(--app-surface-lightest);

                        svg {
                            font-size: 16px;
                            color: $neutral-400;
                        }

                        &:hover {
                            background-color: var(--app-surface-light);
                        }
                    }
                }
            }

            .relationship-actions {
                display: grid;
                //grid-template-columns: 35px auto;
                grid-template-columns: auto;
                gap: 10px;

                .relationship-activator {
                    //width: 100%;
                    height: 58px;
                    line-height: 38px;
                    font-weight: 400;
                    color: $neutral-700;
                    padding: 0 10px;
                    text-align: left;
                    position: relative;
                    box-shadow: 0 0 0 2px var(--app-surface-light);
                    display: flex;
                    justify-content: left;
                    border-radius: 5px;
                    background-color: var(--app-surface-lightest);
                    border-color: var(--neutral-200);

                    span {
                        font-size: 16px;
                        width: 30px;
                        color: var(--app-icon-primary);
                        text-align: center;
                        display: block;
                    }

                    &:disabled {
                        opacity: 1;
                        box-shadow: 0 0 0 3px var(--app-surface-lightest);
                        color: $neutral-300;

                        span {
                            color: $neutral-300;
                        }
                    }
                }
            }

        }
    }

    &.mtska-text-title-sm {
        font-weight: 700;
    }

    &.mtska-text:not(:first-child) {
        margin-top: 20px;
        font-size: 14px;
    }

    .list-system {
        .list-wrapper {
            .MuiDataGrid-main {
                .MuiDataGrid-virtualScroller {
                    .MuiDataGrid-columnHeaders {
                        background-color: transparent;
                    }
                }
            }
        }
    }


    .form-element-field-select {
        &.form-element-field-tiny {
            font-size: 13px;
            line-height: 24px;
            height: 24px;

            .MuiSelect-startDecorator,
            .MuiSelect-endDecorator {
                height: 24px;
            }
        }
    }

    &.form-element-cost-widget {
        position: relative;

        .form-element-cost-timeframe {
            position: absolute;
            right: 0;
            top: -47px;
            width: 200px;
        }

        .form-element-cost-table {
            --Table-headerUnderlineThickness: 1px;
            --TableCell-height: 50px;
            --TableCell-headBackground: var(--neutral-100);

            tbody {
                tr {
                    td {
                        color: $neutral-500;

                        &:first-child {
                            font-weight: 500;
                            color: $neutral-700;
                        }
                    }
                }
            }

            tfoot,
            thead {
                font-weight: 500;
            }
        }
    }

    &.form-element-preview-widget {
        position: relative;

        .textLayer,
        .annotationLayer {
            display: none;
        }

        .preview-pdf {
            canvas {
                border: 5px solid $neutral-100;
                border-radius: 15px;
                margin: 0 auto;
                max-width: 100%;
            }

            .paginator {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .paginator-previous {
                    margin-right: auto;
                }

                .paginator-next {
                    margin-left: auto;
                }

                .paginator-info {
                    text-align: center;
                    flex-grow: 1;
                    font-size: 12px;
                }
            }

        }

        .preview-image {
            text-align: center;

            img {
                border: 5px solid $neutral-100;
                border-radius: 15px;
                margin: 0 auto;
            }
        }

        .download_document {
            position: absolute;
            top: -40px;
            right: 20px;
        }
    }

    &.form-element-savings-widget {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

        }
    }
}

.MuiAutocomplete-listbox {
    li {
        display: flex;
        width: 100%;

        .id.tag {
            &::before {
                content: "ID";
                color: $neutral-400;
                margin-right: 5px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .form-element {
        .form-element-control {
            .form-element-label {
                font-size: 12px;
                @include maxlines(1);
            }

            .form-element-dropbox-area {
                .info {
                    line-height: 25px;
                }
            }
        }
        &.relationship {
            .form-element-relationship-options ul > li {
                overflow-x: scroll;
            }
        }
    }
}