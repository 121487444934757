.list-element-cell {
    display: flex;
    align-items: center;
    height: 100%;

    &.cell-email {
        a {
            vertical-align: middle;
            font-size: 14px;
        }
    }

    &.cell-drawer {
        button {
            border: unset;
            min-height: unset;
            height: 30px;
            padding: 0 10px;
        }

        &.cell-drawer-cars-detail {

            button {
                border: 1px solid $secondary-500;
                border-left-width: 8px;
                border-right-width: 8px;
                border-radius: 3px;
                padding: 2px 5px;
                font-family: monospace;
                letter-spacing: 2px;
            }
        }
    }

    &.cell-generics {
        ul {
            display: inline-flex;
            flex-direction: row;
            gap: 3px;

            li {
                display: inline-block;
                background-color: $neutral-100;
                border: 0.5px solid $neutral-300;
                border-radius: 12px;
                width: auto;
                height: 24px;
                line-height: 24px;
                min-height: unset;
                text-align: center;
                padding: 0 8px;
                box-shadow: $boxshadow;

                span {
                    color: $neutral-700;
                    font-size: 10px;
                    text-transform: uppercase;
                    display: block;
                }

                &.cell-list-item-count {
                    background-color: $neutral-100;
                    border: 0.5px solid $neutral-300;
                    width: auto;
                    min-width: 24px;

                    span {
                        color: $neutral-500;
                        letter-spacing: -1px;
                    }
                }
            }
        }
    }

    &.cell-people,
    &.cell-legalentities,
    &.cell-employees,
    &.cell-employments,
    &.cell-pools,
    &.cell-bis,
    &.cell-cars,
    &.cell-drivers {
        ul {
            display: inline-flex;
            flex-direction: row;
            gap: 3px;

            li {
                &.cell-list-item-count {
                    background-color: $neutral-100;
                    border: 0.5px solid $neutral-300;
                    width: auto;
                    min-width: 24px;

                    span {
                        color: $neutral-500;
                        letter-spacing: -1px;
                    }
                }
            }
        }
    }

    &.cell-people,
    &.cell-bis,
    &.cell-drivers {
        ul {
            li {
                display: inline-block;
                background-color: $success-100;
                border: 0.5px solid $success-300;
                border-radius: 12px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                min-height: unset;
                text-align: center;
                padding: 0 3px;
                box-shadow: $boxshadow;

                span {
                    color: $success-700;
                    font-size: 10px;
                    text-transform: uppercase;
                    display: block;
                }

            }
        }
    }

    &.cell-legalentities,
    &.cell-employees,
    &.cell-employments,
    &.cell-pools,
    &.cell-cars {
        ul {
            li {
                display: inline-flex;
                background-color: $neutral-100;
                border: 0.5px solid $neutral-300;
                border-radius: 12px;
                width: auto;
                height: 24px;
                line-height: 24px;
                min-height: unset;
                text-align: center;
                padding: 0 8px;
                box-shadow: $boxshadow;

                span {
                    color: $neutral-700;
                    font-size: 10px;
                    text-transform: uppercase;
                    display: block;

                    &.secondary {
                        border-radius: 25px;
                        background-color: var(--neutral-200);
                        line-height: 20px;
                        padding: 0 10px;
                        margin-right: -6px;
                    }
                }

                &.missing {
                    background-color: $warning-100;
                    border-color: $warning-200;

                    span {
                        color: $warning-700;
                    }
                }
            }
        }
    }

    &.cell-tracekmslast {
        display: flex;
        flex-direction: column;
        line-height: 14px;
        margin-top: 12px;

        .date {
            font-size: 10px;
            color: $neutral-400;
        }
    }

    &.cell-legalentity {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &.cell-vehicleplate {
        .plate {
            border: 1px solid $secondary-500;
            border-left-width: 8px;
            border-right-width: 8px;
            border-radius: 3px;
            padding: 2px 5px;
            font-family: monospace;
            letter-spacing: 2px;
        }
    }

    &.cell-carsetup {

        .make,
        .model {
            padding-right: 5px;
        }

        .setup {
            color: $neutral-400;
        }
    }

    &.cell-carstatus,
    &.call-status {
        .status {

            &.status-running,
            &.status-STATUS_CREATED,
            &.status-active {
                &:before {
                    background-color: $success-400;
                }
            }

            &.status-order,
            &.status-in_order {
                &:before {
                    background-color: $success-100;
                    border-color: $success-400;
                }
            }

            &.status-in_offer {
                &:before {
                    background-color: $warning-100;
                    border-color: $warning-400;
                }
            }

            &.status-preleasing,
            &.status-STATUS_NEW,
            &.status-in_configuration {
                &:before {
                    background-color: $warning-400;
                }
            }

            &.status-substitute,
            &.status-future {
                &:before {
                    background-color: $secondary-400;
                }
            }

            &.status-inactive,
            &.status-terminated,
            &.status-STATUS_ERROR,
            &.status-closed {
                &:before {
                    background-color: $danger-400;
                }
            }

            &.status-undefined {
                display: none;
            }
        }
    }

    &.cell-contract {
        .status {
            padding-left: 8px;

            svg {
                margin-right: 8px;
            }

            &:before {
                display: none;
            }
        }
    }

    &.cell-boolean {
        justify-content: space-around;
        text-align: center;

        &.cell-boolean-false {
            color: $danger-300;
        }

        &.cell-boolean-true {
            color: $success-300;
        }
    }

    .status {
        border: 1px solid $neutral-100;
        background: $neutral-100;
        padding: 0px 10px 0px 24px;
        border-radius: 15px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        position: relative;
        box-shadow: $boxshadow;

        &:before {
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $neutral-500;
            border: 1px solid $white;
        }

    }

    .company-avatar-wrapper {
        .company-avatar {
            height: 30px;
            width: 30px;
            line-height: 30px;
            min-width: 30px;
            font-weight: 300;
            color:white;
        }

        .company-name {
            line-height: unset;

            &.hasdetail {
                line-height: 15px;
            }
        }
    }
}