.drawer-wrapper {
    --Drawer-current-horizontalSize: 70vw;
    --Drawer-ancestor-horizontalSize: 99vw;

    .MuiDrawer-content {
        width: var(--Drawer-current-horizontalSize);
        background-color: $neutral-100;
        overflow: hidden;

        .drawer-section-header {
            background-color: $white;
            margin: 0px;
            padding: 20px;
            border-bottom: 0.5px solid $neutral-300;
            display: flex;

            .drawer-section-title {
                display: flex;
                gap: 10px;

                .title {
                    margin-left: 5px;
                    color: var(--app-main);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .detail {
                    color: var(--app-main-subdued);
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                }
            }

            .MuiModalClose-root {
                position: static;
                top: 0;
                left: 0;
            }

            .button-main {
                margin-left: auto;
            }

            button {
                position: relative;

                &.MuiButton-colorPrimary {
                    background-color: var(--app-main);
                    color: var(--white);
                }

                &.iconic {
                    background-color: var(--app-surface-light);
                    color: var(--app-surface);
                    font-weight: 700;

                    &:hover {
                        background-color: var(--app-main-shadowed);
                        color: $white;
                    }
                }

                &:disabled {
                    background-color: $neutral-100;
                    color: $neutral-400;
                    display: none;
                }

                .tooltip {
                    position: absolute;
                    top: 120%;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: var(--app-main-shadowed);
                    border-radius: 5px;
                    padding: 0 5px;
                    font-size: 10px;
                    line-height: 18px;
                    color: $neutral-100;
                    font-weight: 400;
                    white-space: nowrap;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 12px;
                        left: 46%;
                        height: 7px;
                        width: 7px;
                        background-color: var(--app-main-shadowed);
                        transform: rotate(45deg) translateX(-50%);
                    }

                    display: none;
                }

                &:hover {
                    .tooltip {
                        display: block;
                    }
                }
            }

            // .buttongroup.MuiButtonGroup-root {
            //     box-shadow: none;
            //     border-radius: 6px;

            //     >button {
            //         &:first-child {
            //             border-top-right-radius: 0;
            //             border-bottom-right-radius: 0;
            //             padding-right: 10px;
            //             font-weight: 300;
            //             font-size: 14px;
            //             border-right: 1px solid var(--app-main-shadowed);
            //         }

            //         &:last-child {
            //             border-top-left-radius: 0;
            //             border-bottom-left-radius: 0;
            //             padding-left: 5px;
            //             padding-right: 5px;
            //             border-left: 1px solid var(--app-main-subdued);
            //         }
            //     }

            //     .MuiButtonBase-root {
            //         text-transform: none;
            //     }
            // }

            // .buttongroup_action_positioner {
            //     z-index: 3;

            //     .buttongroup_action_wrapper {
            //         .buttongroup_action {
            //             // display: grid;
            //             // grid-template-columns: 30px auto;
            //             font-size: 14px;
            //             padding-left: 5px;
            //             padding-right: 15px;

            //             svg {
            //                 width: 30px;
            //             }
            //         }
            //     }
            // }
        }

        .drawer-section-breadcrumb {
            border-top: 1px solid $neutral-300;
            border-bottom: 1px solid $neutral-050;
            background-color: $neutral-200;
            padding: 0 56px;
            font-size: 12px;
            line-height: 24px;
            color: $neutral-600;

            span {
                padding-right: 20px;
                position: relative;

                &::after {
                    content: "\203A";
                    position: absolute;
                    right: 7px;
                    top: -5px;
                    color: $neutral-400;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &:empty {
                    display: none;
                }
            }
        }

        .drawer-section-content {
            padding: 40px;
            overflow-y: auto;

            .drawer-section-content-embedded {
                padding: 0px 3px;
                overflow-y: auto;

                .form-wrapper {
                    padding: 0;

                    .mtska-fieldset {
                        padding: 0;
                        box-shadow: none;
                        border-radius: 0;
                        border: none;
                        margin-bottom: 0;
                    }
                }
            }

            .section-title-embedded {
                border: 1px solid $neutral-200;
                border-radius: 10px 10px 0 0;
                border-bottom: none;
                grid-column: span 12;
                display: grid;
                grid-template-columns: auto 240px;
                gap: 20px;
                padding: 8px 10px 0;
                box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.02);
                margin-bottom: -10px;

                .tagwrapper {
                    text-align: right;

                    .tag {
                        text-align: center;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .MuiDrawer-backdrop {
        backdrop-filter: blur(1px);
        background-color: $primary-500-a30;
    }

    &.drawer-wrapper-fullwidth-true {
        .MuiDrawer-content {
            width: var(--Drawer-ancestor-horizontalSize);

            .drawer-section-breadcrumb {
                span {
                    color: $neutral-200;

                    &::after {
                        color: $neutral-200;
                    }
                }
            }

            // orrendo hack per evitare di far vedere una lista di car guidate dal driver popolata dal contenuto della lista di car guidabili
            .cars-of-person {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .drawer-wrapper {
        --Drawer-current-horizontalSize: 100vw;
        --Drawer-ancestor-horizontalSize: 100vw;

        .MuiDrawer-content {
            .drawer-section-header {

                .drawer-section-title {
                    .title {
                        @include maxlines(1);
                        line-height: 40px;
                    }
                }

                button {
                    font-size: 10px;
                    font-weight: 400;
                    @include maxlines(1);

                    &.regular {
                        padding: 0 10px;
                    }

                    &[disabled] {
                        display: none;
                    }
                }

                // .buttongroup {
                //     &.MuiButtonGroup-root {
                //         >button {
                //             &:first-child {
                //                 white-space: nowrap;
                //                 font-size: 12px;
                //                 font-weight: 300;
                //                 letter-spacing: normal;
                //                 @include maxlines(1);

                //                 .MuiButton-startDecorator {
                //                     display: none;
                //                 }
                //             }
                //         }
                //     }
                // }
            }

            .drawer-section-breadcrumb {
                display: none;
            }
            .drawer-section-content {
                padding: 40px calc(100% / 24);
            }
        }
    }
}