.service-avatar {
    display: grid;
    width: 100%;
    gap: 2px 10px;
    grid-template-columns: 100px auto;
    grid-template-rows: 35px 20px 20px;

    padding: 20px;
    border-radius: $borderradius;
    border:$boxborder;
    box-shadow: $boxshadow;

    background-color: $primary-050;
    border-color: $primary-500;
    border-bottom: 5px solid $primary-500;

    color: $primary-800;

    .service-type {
        grid-column: 1 / -1;
        font-size: 14px;
        font-weight: 600;
        svg {
            margin-right: 5px;
        }
    }
    .label {
        font-size: 11px;
        font-weight: 400;
        @include maxlines(1);
    }
    .value {
        font-size: 14px;
        font-weight: 400;
        @include maxlines(1);
        &.service-storagelocation {
            
        }
    }
    .action {
        padding-top: 15px;
    }
}