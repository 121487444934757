.info-header {
    padding: 20px 30px 20px;
    box-shadow: $boxshadow;
    background-color: $primary-050;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 145px auto 150px;
    gap: 30px;
    font-family: $font-primary;
    margin: 0 15px 40px;

    &.info-header-vehicle {
        grid-template-columns: 300px auto;

        .company-avatar-wrapper {
            .error {
                cursor: pointer;
            }
        }
    }

    .tag-danger {
        cursor: pointer;

        &:hover {
            background-color: $danger-600;
            color: $danger-100;
        }
    }

    &.info-header-wizard {
        grid-template-columns: auto;
        padding: 0;
        box-shadow: none;
        background: transparent;
        margin-bottom: 0;

        .info-wrapper {
            margin-top: 0;

            .title {
                display: block;
                font-size: 36px;
                letter-spacing: -1px;
            }

            .subtitle {
                display: block;
                font-size: 14px;
                font-weight: 200;
            }
        }
    }

    .person-avatar,
    .company-avatar {
        width: 145px;
        height: 145px;
        border: 4px solid $white;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        overflow: hidden;
        line-height: 145px;
        font-size: 50px;
        font-weight: 200;
    }

    .vehicle-avatar {
        width: 100%;
        height: 210px;
        max-height: 210px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background-color: $neutral-200;

        img {
            aspect-ratio: unset;
            width: calc(100% + 4px);
            margin-left: -2px;
            margin-right: -2px;
        }
    }

    .info-wrapper {
        margin: 20px 0;

        .info-summary-external {
            display: flex;
            line-height: 21px;
            gap: 10px;
            font-size: 14px;
            color: $neutral-600;

            .company-avatar-wrapper {
                margin-right: 10px;

                .company-avatar {
                    width: 21px;
                    height: 21px;
                    line-height: 21px;
                    font-size: 10px;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
                    border: none;
                }

                .company-name {
                    font-size: 12px;
                    @include maxlines(1);
                }
            }

            .vehicle-avatar-wrapper {
                .vehicle-avatar {
                    width: 21px;
                    height: 21px;
                    line-height: 21px;
                    font-size: 10px;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
                    border: none;

                    img {
                        opacity: 1;
                        object-fit: cover;
                        filter: none;

                        &.default {
                            width: 200px;
                            transform: translateX(-12px) translateY(-10px);
                        }
                    }
                }
            }

            .error {
                background-color: $danger-100;
                color: $danger-500;
                font-size: 10px;
                border-radius: 10px;
                padding: 0 10px;

                svg {
                    margin-right: 5px;
                }
            }
        }

        .info-name {
            display: flex;
            gap: 10px;
            line-height: 50px;
            font-size: 30px;
            padding: 10px 0;

            .primary {
                white-space: nowrap;
            }

            .secondary {
                color: $neutral-400;
                white-space: normal;
                max-width: 50%;
                @include maxlines(1);
            }
        }

        .info-appandcompanyroles {
            margin-top: 10px;
            display: flex;
            gap: 10px;
        }

        .info-contacts {
            margin-top: 10px;
            padding: 0;
            display: flex;
            flex-direction: row;
            gap: 10px;

            li {
                display: inline;
                padding: 0px;
                padding-right: 10px;
                line-height: 20px;
                height: 24px;
                min-height: 24px;
                max-height: 24px;
                font-size: 14px;
                width: auto;

                .mtska-link {
                    font-size: 14px;

                    &.iconic {
                        .MuiLink-startDecorator {
                            margin-right: 0;
                            font-size: 16px;
                        }
                    }

                    &:hover {
                        color: $secondary-500;
                    }
                }

                border-right: 0.5px solid $neutral-300;

                &:last-child {
                    border-right: none;
                }
            }
        }

        .info-contract {
            font-size: 10px;

            span {
                color: $neutral-400;
                margin-right: 5px;
            }
        }

        .info-details {
            margin-top: 10px;
            padding: 0;
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: stretch;

            li {
                flex: 1 1 0px;
                display: flex;
                flex-direction: column;
                padding: 0px;
                height: 60px;
                text-align: left;

                .key {
                    display: block;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 400;
                    color: $neutral-400;
                    white-space: nowrap;
                }

                .value {
                    display: flex;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;

                    .person-avatar-wrapper {
                        gap: 5px;
                        transform: translateY(-5px);

                        &.person-avatar-withname-false,
                        &.person-avatar-withname-initial {
                            margin-right: 5px;
                        }

                        .person-avatar {
                            width: 28px;
                            height: 28px;
                            line-height: 28px;
                            font-size: 12px;
                            border: none;
                            box-shadow: none;
                        }

                        .person-name {
                            font-size: 12px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }

    .info-activity-wrapper {
        padding-top: 0px;
        font-size: 14px;
        display: flex;
        flex-direction: column;

        .pair {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .key {
                font-size: 10px;
                margin-top: 10px;
                font-weight: 200;
            }

            .value {
                font-weight: 400;
            }
        }
    }

    .vehicle-installed-termometer {

        .trace,
        .contract {
            height: 40px;
            background-color: $white;
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 0px;
            box-shadow: $boxshadow;
            height: 80px;
        }

        .analysis {
            transform: translateY(20px);
        }

        height: 80px;
        grid-column: 1 / 3;
    }

    .mtska-chart-vehicle-kilometer {
        grid-column: 1 / 3;

        display: grid;
        grid-template-columns: auto 30%;
        gap: 25px;
        background-color: $white;
        border-radius: 10px;
        padding: 10px 20px 20px;
        margin-bottom: 20px;
        box-shadow: $boxshadow;
        height: 300px;

        .chartwrapper {
            width: 100%;

            >div[type="area"] {
                background-color: transparent;
            }

            .apexcharts-toolbar {
                >div {
                    transform: scale(.5);

                    &.apexcharts-pan-icon {
                        transform: scale(.4);
                    }

                    &.apexcharts-zoom-icon {
                        transform: scale(.6);
                    }

                    &.apexcharts-menu {
                        transform: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .info-header {
        margin-left: 0;
        margin-right: 0;

        &.info-header-vehicle,
        &.info-header-person,
        &.info-header-driver {
            grid-template-columns: 100%;
            padding: 14px;
            gap: 10px;
            margin-bottom: 20px;

            .info-wrapper,
            .vehicle-installed-termometer,
            .mtska-chart-vehicle-kilometer,
            .vehicle-avatar-wrapper {
                grid-column: 1/1;
            }

            .vehicle-avatar-wrapper {
                .vehicle-avatar {
                    // display: inline;
                    // border-radius: 0;
                    // box-shadow: none;
                    // height: 120px;

                    img {
                        // border-radius: 50%;
                        // width: 100px;
                        // aspect-ratio: 1/1;
                    }
                }
            }

            .person-avatar-wrapper {
                .person-avatar {
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 15px;
                    border: 0;
                    margin: 0 auto;
                }
            }


            .info-wrapper {
                margin: 10px 0;

                .info-summary-external {
                    display: grid;
                    grid-template-columns: repeat(8, 1fr);
                    gap: 10px 5px;

                    .company-avatar-wrapper,
                    .vehicle-avatar-wrapper {
                        margin: 0;
                        grid-column: span 4;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        // border-bottom: 0.5px solid var(--neutral-300);
                        // padding-bottom: 10px;

                        .company-name {
                            line-height: 14px;
                            flex-grow: 0;

                            .fullname {
                                @include maxlines(1)
                            }
                        }

                        .vehicle-avatar {
                            border-radius: 50%;

                            img {
                                width: 50px;
                                transform: translateX(1px) translateY(-18px);
                                object-fit: fill;
                            }
                        }
                    }

                    p {
                        grid-column: span 2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;

                        .MuiTypography-startDecorator {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .info-appandcompanyroles {
                    justify-content: space-between;
                    flex-direction: column;
                }

                .info-name {
                    padding-top: 24px;
                    flex-direction: column;
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    gap: 5px;

                    .secondary {
                        max-width: 100%;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }

                .info-details {
                    flex-wrap: wrap;
                    gap: 10px;

                    li {
                        height: auto;
                        gap: 10px;
                        min-width: 25%;

                        .key {
                            font-size: 10px;
                        }
                    }
                }

                .info-contacts {
                    li {
                        flex: 1;
                        text-align: center;

                        a {
                            span {
                                font-size: 15px;
                            }

                            font-size: 0;
                        }
                    }
                }
            }

            .vehicle-installed-termometer {
                &.layout-aside {
                    grid-template-columns: auto 67px;
                    grid-template-rows: auto;
                    height: auto;
                    gap: 10px 5px;

                    .trace {}

                    .contract {
                        grid-column: 1;
                        grid-row: 2;
                    }

                    .analysis {
                        transform: translateY(0px);
                        grid-row: 1 / span 2;

                        .tag {
                            height: 50px;
                            line-height: 45px;
                            transform: translateY(54px);
                        }

                        .upIndicator {
                            top: 45px;
                        }

                        .downIndicator {
                            transform: rotate(0deg);
                            bottom: 45px;
                            left: 0;
                        }
                    }

                }
            }

            .mtska-chart-vehicle-kilometer {
                grid-template-columns: auto;
                grid-template-rows: auto;
                height: auto;
                gap: 0;
            }

            .info-activity-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 5px 10px;

                .pair {
                    grid-column: span 1;
                }
            }
        }

        &.info-header-wizard {

            .info-wrapper {
                margin-bottom: 0;

                .title {
                    display: block;
                    font-size: 24px;
                    line-height: 32px;
                    padding: 10px 0;
                }

                .subtitle {
                    display: block;
                    font-size: 12px;
                    font-weight: 200;
                }
            }
        }


        .mtska-chart-vehicle-kilometer {
            .forecastwrapper {
                .result {
                    margin-top: 30px;
                }
            }
        }
    }
}