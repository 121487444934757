.vehicle-avatar-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .vehicle-avatar {
        width: 40px;
        height: 40px;
        overflow: hidden;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;

            &.default {
                opacity: 0.2;
                filter: blur(2px);
            }
        }
    }
    .vehicle-name {
        line-height: 40px;

        &.hasdetail {
            display: flex;
            flex-direction: column;
            line-height: 15px !important;
            .detail {
                font-size: 10px;
                color: $neutral-400;
            }
        }
    }
}