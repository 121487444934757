.dashboard-menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    list-style-type: none;
    padding: 0;
    margin: 50px 0 0;

    &.dashboard-menu-1col {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    &.dashboard-menu-5col {
        grid-template-columns: repeat(5, 1fr);
    }

    .dashboard-section-title {
        grid-column: 1 / -1;
        margin-top: 40px;
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        h2 {
            margin: 0;
            color: $primary-600;
        }

        .buttonized {
            position: absolute;
            top: 5px;
            right: 0;
        }
    }

    .dashboard-menu-itemgroup {
        display: flex;
        gap: 20px;

        .dashboard-menu-item {
            flex: 1;
            max-height: 280px;

            &.autoheight {
                max-height: unset;
                height: auto;
                padding-bottom: 10px;
            }

            .mtska-chart {
                p {
                    color: $primary-500;
                    margin-bottom: 10px;
                }

                .chartwrapper {
                    max-height: 200px;
                    height: 100%;
                    position: relative;
                    // > div[type="donut"] {
                    //     .apexcharts-canvas {
                    //         width: auto !important;
                    //         height: 100% !important;
                    //         min-height: unset !important;
                    //     }
                    // }
                }
            }

            &.mtska-chart-kpi {
                max-height: 140px;

                .iconwrapper {
                    border-color: $primary-500;
                    background-color: $primary-500;

                    svg {
                        color: $white;
                    }
                }

                .title {
                    color: $primary-500;
                }

                .score {
                    color: $primary-600;
                    font-size: 30px;
                    line-height: 50px;
                }
            }
        }
    }

    .dashboard-menu-item {
        padding: 30px;
        border: $boxborder;
        box-shadow: $boxshadow;
        border-radius: $borderradius;

        color: $neutral-800;


        >svg {
            font-size: 30px;
        }

        h2 {
            color: $danger-500;
        }

        .dashboard-submenu {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .dashboard-submenu-item {
                font-size: 12px;
                line-height: 20px;
                color: $neutral-300;

                &.hidden {
                    display: none;
                }

                a {
                    color: $neutral-800;
                    text-decoration: none;
                    font-size: 12px;

                    &:visited {
                        color: $neutral-800;
                    }

                    &:hover {
                        color: $danger-500;
                    }
                }
            }
        }


        &.dimmed {
            svg {
                color: $neutral-300;
            }

            h2 {
                color: $neutral-400;
            }
        }
    }


}

.dashboard {
    ul.dashboard-portion {
        display: grid;
        gap: 30px;
        padding: 0;
        margin: 0;
        margin-top: 30px;
    }

    .dashboard-configuration-wrapper {
        .dashboard-configuration-cta {
            margin: 40px 0px;
            padding: 20px;
            border-radius: 10px;
            background-color: $neutral-100;
            display: grid;
            gap: 20px;
            grid-template-columns: minmax(0, 100px) auto;

            img {
                max-width: 100%;
                max-height: 100px;
                border-radius: 50%;
            }

            p {
                display: block;
                margin-bottom: 20px;

                &.mtska-text-title-sm {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .buttonized {
                font-weight: 300;
            }
        }
        .dashboard-configuration-list {
            padding: 0;
            align-items: flex-start;
        }
        .vehicle-configuration {
            background-color: $primary-050;
            padding: 10px 20px;
            border-radius: 10px;
            .date {
                font-size: 12px;
            }
            .category {
                margin-top: 5px;
                margin-bottom: 15px;
                margin-left: -5px;
            }
            .description {
                font-size: 14px;
                font-weight: 700;
            }
            .status {
                margin-top: 15px;
                font-size: 12px;
                color:$primary-500;
            }
            .warning_process {
                margin-top: 15px;
                font-size: 12px;
                background-color: $neutral-100;
                color: $neutral-600;
                padding: 10px;
                border-radius: 5px;
            }
            .warning_process_send {
                background-color: $danger-100;
                color: $danger-600;
            }
            .warning_process_wait {
                background-color: $warning-100;
                color: $warning-600;
            }
            .warning_process_success {
                background-color: $success-100;
                color: $success-600;
            }
        }
    }

    .dashboard-portion-title {
        font-weight: 700;
        color: $primary-600;
        font-size: 18px;
        margin: 50px 0 10px;

        &:first-child {
            margin-top: 0;
            mix-blend-mode: multiply;
        }
    }

    .dashboard-portion-subtitle {
        color: $neutral-400;
        font-size: 14px;
    }

    .dashboard-portion-driver-info {
        background-color: $neutral-100;
        border-radius: $borderradius;
        box-shadow: $boxshadow;
        border: $boxborder;
        padding: 30px;
    }

    &.dashboard-driver {
        position: relative;
        margin-top: 40px;

        &:before {
            content: "";
            background-color: $white;
            width: calc(100% + 34px);
            height: 140px;
            position: absolute;
            top: -40px;
            left: -17px;
            z-index: -1;
            border-radius: 0px 0px 20px 40px;
        }

        .vehicle-avatar-wrapper,
        .person-avatar-wrapper {

            .vehicle-avatar,
            .person-avatar {
                width: 50px;
                height: 50px;
                min-width: 50px;
                line-height: 50px;
                border-radius: 50%;
                font-size: 18px;
                margin-left: -10px;
                margin-right: 10px;
            }

            .vehicle-avatar {
                margin-left: -15px;
                margin-right: 15px;

                img.default {
                    opacity: 0.9;
                }
            }

            .vehicle-name,
            .person-name {
                line-height: 18px !important;

                .fullname {
                    font-size: 18px;
                }

                .detail {
                    font-size: 14px;
                }
            }

            .person-name {
                max-width: calc(100% - 50px);

                .fullname {
                    color: $primary-900;
                    font-weight: 700;
                    @include maxlines(1)
                }

                .detail {
                    color: $primary-200;
                    @include maxlines(1)
                }
            }
        }

        .vehicle-avatar-wrapper {
            margin-bottom: 20px;

            .vehicle-name {
                .fullname {
                    font-weight: 700;
                }
            }
        }

        .company-avatar-wrapper {
            margin-bottom: 20px;
        }

        .driving-licence-avatar {
            margin-top: 30px;
            margin: 30px -20px -20px;
            padding: 20px;
            background-color: $white;
            border-radius: 10px;

            &:before {
                content: "";
                background-color: $primary-300;
                width: calc(100% + 34px);
                height: 210px;
                position: absolute;
                top: -40px;
                left: -17px;
                z-index: -2;
                border-radius: 0px 0px 20px 40px;
                background: $primary-300;
                background-size: 400% 400%;
                animation: gradient 15s ease infinite;
            }
        }

        .dashboard-vehicle-list {
            padding-left: 0;
            padding-right: 0;
        }

        .dashboard-documents-list-wrapper {
            padding: 0;

            .dashboard-documents-list-section {
                padding: 0;
                flex-direction: column;

                .dashboard-documents-list-section-title {
                    text-align: left;
                    font-size: 14px;
                    display: block;
                    width: 100%;
                }

                .dashboard-documents-list-section-list {
                    width: 100%;
                    padding-top: 0;
                    padding-bottom: 30px;

                    .dashboard-documents-list-section-list-element {
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }

        .dashboard-services-list-wrapper {
            padding: 0;

            .dashboard-services-list-section {
                padding: 0;
                flex-direction: column;

                .dashboard-services-list-section-title {
                    text-align: left;
                    font-size: 14px;
                    display: block;
                    width: 100%;
                }

                .dashboard-services-list-section-list {
                    width: 100%;
                    padding-top: 0;
                    padding-bottom: 30px;

                    .dashboard-services-list-section-list-element {
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &.dashboard-driver-help {
        .smielato {
            line-height: 24px;
            font-size: 16px;
            font-weight: 300;
            position: relative;

            &:before {
                content: "";
                background-color: $white;
                width: calc(100% + 34px);
                height: 50px;
                position: absolute;
                top: 290px;
                left: -17px;
                z-index: -1;
                border-radius: 0px 0px 20px 40px;
            }
        }

        h4 {
            margin-bottom: 0;
        }

        .renterSelecter {
            display: flex;
            list-style-type: none;
            margin: 35px 0 50px;
            gap: 10px;
            padding: 0;
            position: relative;

            &:before {
                content: "";
                background-color: $primary-300;
                width: calc(100% + 34px);
                height: 100px;
                position: absolute;
                top: -55px;
                left: -17px;
                z-index: -2;
                border-radius: 0px 0px 20px 40px;
                background: $primary-300;
                background-size: 400% 400%;
                animation: gradient 15s ease infinite;
            }
        }

        .assistance-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: auto;

            &:first-of-type {
                .assistance-item {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        .assistance-item {
            background: #f8f9fa;
            padding: 15px;
            border-radius: 8px;

            &:first-child {
                padding: 0;
                box-shadow: none;
                background-color: transparent;
                margin-top: 50px;
            }

            h2 {
                margin-bottom: 0px;
                color: $primary-500;
            }

            h3 {
                margin-bottom: 10px;
                color: $primary-600;
                font-size: 16px;
            }

            p {
                margin-bottom: 10px;
                font-size: 14px;
                color: $neutral-800;
            }
        }

        .assistance-details {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .brand {
                background: #fff;
                padding: 10px;
                border-radius: 5px;
                font-size: 14px;
                color: $neutral-800;
            }
        }

        &.filtered {
            .brand {
                display: none;
            }
        }

        &.arval {
            .brand.arval {
                display: block;
            }
        }

        &.leaseplan {
            .brand.leaseplan {
                display: block;
            }
        }

        &.leasys {
            .brand.leaseplan {
                display: block;
            }
        }

        &.ald {
            .brand.ald {
                display: block;
            }
        }

        &.ayvens {
            .brand.ayvens {
                display: block;
            }
        }

        &.vwleasing {
            .brand.vwleasing {
                display: block;
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .dashboard {
        ul.dashboard-portion {
            display: flex;
        }

        &.dashboard-driver-help {
            .assistance-details {
                flex-direction: row;
                flex-wrap: wrap;

                .brand {
                    flex: 1 1 45%;
                }
            }
        }
    }
}